<lib-menu-container>
  <lib-menu-item
    *ngFor="let menuItem of menuItems"
    (click)="menuItem.onClick()"
    [tooltip]="menuItem.tooltip | translate"
    [icon]="menuItem.icon"
    [isActive]="isMenuItemActive(menuItem)"
  >
    <lib-attention-badge *ngIf="menuItem.showBadge" [counter]="menuItem.counter | async"></lib-attention-badge>
  </lib-menu-item>
</lib-menu-container>
