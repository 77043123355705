<section class="modal-dialog-box">
  <div class="material-dropdown" *ngIf="!preselectedMaterialFilter">
    <label class="material-dropdown__label">{{ 'MOUNTING.MATERIAL' | translate }}</label>
    <lib-dropdown
      [inlineStyle]="{ width: '550px' }"
      [options]="dropDownOptions"
      [(ngModel)]="selectedOption"
      (ngModelChange)="filterWarehouseData()"
      [disabled]="isDropDownOptionDisabled"
    ></lib-dropdown>
  </div>

  <div class="material-table">
    <pt-material-table
      [tableColumns]="columns"
      [tableData]="warehouseFilteredData"
      [selectedRow]="selectedMaterial"
      (selectedRowChanged)="selectedMaterialChange($event)"
    ></pt-material-table>
  </div>

  <div *ngIf="isMaterialQuantityRequired" class="consume-quantity">
    <div class="consume-quantity__wrapper">
      <span class="consume-quantity__label">{{ 'MOUNTING.QUANTITY' | translate }}</span>
      <lib-input-number
        [(ngModel)]="selectedMaterialQuantity"
        [inputStyle]="{ width: '80px', textAlign: 'right' }"
        [min]="MINIMUM_MATERIAL_QUANTITY"
        [step]="0.1"
      ></lib-input-number>
      <span class="consume-quantity__label">
        <span *ngIf="selectedMaterial">{{ selectedMaterialQuantityUnitId }}</span>
        <span *ngIf="!selectedMaterial">‒</span>
      </span>
    </div>
  </div>
</section>
<lib-modal-footer
  [whenLoadingTopic]="loadingTopic"
  [submitLabel]="submitButtonLabel"
  (submitClicked)="onSubmitClick()"
  [submitDisabled]="!isSubmitEnabled()"
  (declineClicked)="onDeclineClick()"
></lib-modal-footer>
